<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card class="card" :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客户简称">
                <a-input v-model="queryParam['%short_name']"/>
              </a-form-item>
            </a-col>
            <a-col :xs="6">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </keep-alive>
</template>

<script>
import { STable } from '@/components'
import { getCommonPage, deleteCommonInfo } from '@/api/common'
export default {
  components: {
    STable
  },
   data() {
    return {
      queryParam: {},
      customerOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '客户简称',
          dataIndex: 'short_name',
          width: 120
        },
        {
          title: '简码',
          dataIndex: 'abbr',
          width: 100
        },
        {
          title: '客户全称',
          dataIndex: 'full_name',
          width: 200
        },
        {
          title: '开票税率',
          dataIndex: 'tax_rate',
          width: 100
        },
        {
          title: '共享档案',
          dataIndex: 'share_type',
          customRender: (text) => {
            return text === 0 ? '私有' : '公有'
          },
          width: 100
        },
        {
          title: '客户地址',
          dataIndex: 'address',
          width: 120
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      loadData: parameter => {
        return getCommonPage('customer', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'Customer' && this.queryParam) {
        newRoute.meta.title = '客户信息'
        if (newRoute.query.isEdit) {
          Object.assign(this.currentData, newRoute.params)
        } else if (newRoute.query.isEdit === false) {
          this.$refs.table.refresh()
        }
      }
    }
  },
  created() {
    // 1
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: 'CustomerCUR',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.currentData = record
      this.$router.push({
        name: 'CustomerCUR',
        params: record
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteCommonInfo('customer', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
